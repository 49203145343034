import React from 'react';

const BienvenidaAdmin = () => {
  return (
    <div className="container">
      <img 
        src={require('../../assets/admin.jpg')}
        alt="Caja Fuerte" 
        style={{ 
          marginTop: '20px', 
          maxWidth: '100%', // Ajusta esto para controlar el tamaño máximo de la imagen
          width: '30%', // Ajusta el ancho de la imagen aquí
          height: 'auto',
          display: 'block', // Usa display block para permitir margen automático
          marginLeft: 'auto', // Margen automático a la izquierda
          marginRight: 'auto', // Margen automático a la derecha
          borderRadius: 10
        }} 
      />
      <h1>Bienvenido al Panel de Administración</h1>
      <p>¡Es un placer tenerte de vuelta!</p>
    </div>
  );
};

export default BienvenidaAdmin;
