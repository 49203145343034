import React, { useState, useEffect } from 'react';

const AgregarProducto = ({ onAgregarProducto, onCancelar }) => {
  const [producto, setProducto] = useState({
    nombre: '',
    precio: '',
    descripcion: '',
    caracteristicas: '',
    material: '',
    imagen: '',
  });

  useEffect(() => {
    if (!window.cloudinary) {
      return;
    }

    const widget = window.cloudinary.createUploadWidget({
      cloudName: 'dr23mzpqi',
      uploadPreset: 'lrdtojlm',
    }, (error, result) => {
      if (!error && result && result.event === 'success') {
        setProducto(prev => ({ ...prev, imagen: result.info.secure_url }));
      }
    });
    document.getElementById('upload_widget').addEventListener('click', function () {
      widget.open();
    }, false);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProducto(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const productoParaEnviar = {
      ...producto,
      caracteristicas: producto.caracteristicas.split(',').map(caract => caract.trim()),
    };
    onAgregarProducto(productoParaEnviar);
  };

  return (
    <div className="container">
      <h2>Agregar Producto</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Nombre:</label>
          <input name="nombre" type="text" value={producto.nombre} onChange={handleChange} required />
        </div>
        <div>
          <label>Precio:</label>
          <input name="precio" type="number" value={producto.precio} onChange={handleChange} required />
        </div>
        <div>
          <label>Descripción:</label>
          <textarea name="descripcion" value={producto.descripcion} onChange={handleChange} required />
        </div>
        <div>
          <label>Características:</label>
          <input name="caracteristicas" type="text" value={producto.caracteristicas} onChange={handleChange} placeholder="Ingresa las características separadas por comas" />
        </div>
        <div>
          <label>Material:</label>
          <select name="material" value={producto.material} onChange={handleChange} required>
            <option value="">Seleccione un material</option>
            <option value="Acero">Acero</option>
            <option value="Madera reforzada">Madera reforzada</option>
          </select>
        </div>
        <div>
          <button type="button" id="upload_widget" >Subir Imagen</button>
        </div>
        {producto.imagen && <div>
          <label>Imagen:</label>
          <img src={producto.imagen} alt="Vista previa" style={{ maxWidth: '300px', maxHeight: '300px' }} />
        </div>}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
          <div>
            <button type="submit">Agregar</button>
          </div>
          <div>
            <button type="button" onClick={onCancelar}>Cancelar</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AgregarProducto;
