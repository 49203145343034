import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';


const ActualizarEmpresa = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { empresa } = location.state;
  const [contenido, setContenido] = useState(empresa.contenido);

  const actualizarContenido = async () => {
    try {
      await axios.put(`https://ironsafe-beta.vercel.app/datos-empresa/${empresa._id}`, { contenido });
      Swal.fire({
        title: '¡Actualizado!',
        text: 'La información de la empresa ha sido actualizada con éxito.',
        icon: 'success',
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/empresa');
        }
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'No se pudo actualizar la información de la empresa.',
        icon: 'error',
        confirmButtonText: 'Cerrar'
      });
      console.error('Error al actualizar:', error);
    }
  };

  return (
    <div className="container">
      <h2>Actualizar {empresa.titulo}</h2>
      <textarea value={contenido} onChange={(e) => setContenido(e.target.value)} />
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
        <div>
          <button onClick={actualizarContenido}>Actualizar</button>
        </div>
      </div>
    </div>
  );
};

export default ActualizarEmpresa;
