import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Politicas = () => {
  const [mision, setMision] = useState('');
  const [vision, setVision] = useState('');

  useEffect(() => {
    axios.get('https://ironsafe-beta.vercel.app/datos-empresa')
      .then(response => {
        const datos = response.data;
        datos.forEach(dato => {
          if (dato.titulo === 'Nuestra Misión') {
            setMision(dato.contenido);
          } else if (dato.titulo === 'Nuestra Visión') {
            setVision(dato.contenido);
          }
        });
      })
      .catch(error => console.error("Error al obtener los datos de políticas:", error));
  }, []);

  return (
    <div className="politicas-container">
      <div className="politicas-column">
        <h2 className="politicas-title">Acerca de IRON SAFE</h2>
        <p className="politicas-text">
          IRON SAFE es pionera en la integración de tecnología de punta en sistemas de seguridad personalizados. 
          Nuestra gama de cajas fuertes inteligentes está diseñada para ofrecer la máxima protección, 
          incorporando lo último en reconocimiento biométrico, conectividad IoT y materiales resistentes de alta calidad.
        </p>
        <p className="politicas-text">
          Con una trayectoria de innovación y excelencia, nos esforzamos por superar las expectativas de 
          seguridad en hogares y empresas, garantizando tranquilidad y protección a nuestros clientes.
        </p>
        <img src={require("../assets/mision.jpg")} className="politicas-img" alt="Acerca de IRON SAFE" />
      </div>
      <div className="politicas-column">
        <img src={require("../assets/vision.jpg")} className="politicas-img" alt="Nuestra Misión y Visión" />
        <h2 className="politicas-title">Nuestra Misión</h2>
        <p className="politicas-text">{mision}</p>
        <h2 className="politicas-title">Nuestra Visión</h2>
        <p className="politicas-text">{vision}</p>
      </div>
    </div>
  );
};

export default Politicas;
