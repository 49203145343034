import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from '../Administrador/Modal';
import { useAuth } from '../AuthContext';
import Swal from 'sweetalert2';

export default function Estadistica() {
  const [sensores, setSensores] = useState([]);
  const [estadoPuerta, setEstadoPuerta] = useState('Desconocido');
  const [mostrarModal, setMostrarModal] = useState(false);
  const [pin, setPin] = useState('');
  const { userMac } = useAuth();
  const [pinRegistrado, setPinRegistrado] = useState(false);
  const [showPinAlert, setShowPinAlert] = useState(false);


  useEffect(() => {
    const cargarDatosSensores = async () => {
      try {
        const respuesta = await axios.get('https://ironsafe-beta.vercel.app/estados-dispositivos');
        setSensores(respuesta.data);
        const sensorPuerta = respuesta.data.find(sensor => sensor.sensor === 'Puerta');
        if (sensorPuerta) {
          setEstadoPuerta(sensorPuerta.dato);
        }
      } catch (error) {
        console.error('Error al obtener datos de sensores:', error);
      }
    };


    cargarDatosSensores();
    const intervalId = setInterval(cargarDatosSensores, 100);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const verificarPinRegistrado = async () => {
      try {
        const response = await axios.post('https://ironsafe-beta.vercel.app/registro-pin', {
          mac: userMac,
        });
        if (response.data.message === "Pin encontrado") {
          setPinRegistrado(true);
        } else {
          setPinRegistrado(false);
          setShowPinAlert(true);
        }
      } catch (error) {
        console.error('Error al verificar PIN:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'No tienes un pin registrado a tu caja fuerte',
        });
      }
    };

    verificarPinRegistrado();
  }, [userMac]);

  const abrirModal = () => {
    if (pinRegistrado) {
      setMostrarModal(true);
    }
  };

  const cerrarModal = () => setMostrarModal(false);

  const actualizarPin = (e) => setPin(e.target.value);

  const enviarPin = async () => {
    try {
      const response = await axios.post('https://ironsafe-beta.vercel.app/encontrar-mqtt', {
        pin: pin,
        mac: userMac,
      });
      if (response.data.message === "Pin encontrado") {
        Swal.fire({
          icon: 'success',
          title: 'PIN correcto',
          text: 'La caja se ha abierto.',
        });
        cerrarModal();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'PIN incorrecto.',
        });
      }
    } catch (error) {
      console.error('Error al enviar PIN:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error al verificar el PIN. Por favor, intenta de nuevo.',
      });
    }
  };

  function IndicadorLED({ estado }) {
    const estiloLED = {
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      backgroundColor: estado === 'Movimiento Detectado' ? 'red' : 'green',
      margin: '0 auto',
      display: 'block',
    };

    const claseLED = estado === 'Movimiento Detectado' ? 'ledParpadeo' : '';

    return <div style={estiloLED} className={claseLED}></div>;
  }

  function IndicadorEstadoPuerta() {
    const iconoPuerta = estadoPuerta === 'Abierta' ? require("../../assets/puerta_abierta.png") : require("../../assets/puerta_cerrada.png");
    const altTexto = estadoPuerta === 'Abierta' ? "Puerta Abierta" : "Puerta Cerrada";
    return (
      <div>
        <img src={iconoPuerta} alt={altTexto} style={{ width: 100, height: 100 }} />
        <p>La puerta está: {estadoPuerta}</p>
      </div>
    );
  }

  return (
    <div className="safeArea">
      <div className="container">
        <h1 className="titulo">Caja Fuerte</h1>
        <p className="textoCajas">Estadísticas del dispositivo</p>

        <div className="dashboard">
          <div className="estadoPuerta">
            <h3>Estado de la Puerta</h3>
            <IndicadorEstadoPuerta />
          </div>

          <div className="estadosSensores">
            {sensores.filter(sensor => sensor.sensor !== 'Puerta').map((sensor, index) => (
              <div key={index} className="widget">
                <h3>{sensor.sensor}</h3>
                <IndicadorLED estado={sensor.dato} />
                <p>Estado: {sensor.dato}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="botonera">
          <button className="botonVerde" onClick={abrirModal}>Abrir Caja</button>
        </div>

        {mostrarModal && (
          <Modal onClose={cerrarModal}>
            <div>
              {showPinAlert && (
                <div>
                  <p>No hay un PIN registrado. Por favor, registra un PIN primero.</p>
                </div>
              )}
              {pinRegistrado && (
                <input
                  type="number"
                  value={pin}
                  onChange={actualizarPin}
                  placeholder="Introduce el PIN"
                  style={{ width: '100%' }}
                />
              )}
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <button onClick={enviarPin}>Enviar</button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
}