import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import fondo1 from '../../assets/fondo1.jpg';
import fondo2 from '../../assets/fondo2.png';
import fondo3 from '../../assets/fondo3.png';

const Galeria = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <div className="galeria-container">
      <Slider {...settings}>
        <div>
          <img src={fondo1}  alt="Imagen 1" style={{width: "700px"}} />
        </div>
        <div>
          <img src={fondo2}  alt="Imagen 2" style={{width: "600px"}} />
        </div>
        <div>
          <img src={fondo3}  alt="Imagen 3" style={{width: "550px"}} />
        </div>
      </Slider>
    </div>
  );
};

export default Galeria;
