import React, { useState, useEffect } from 'react';

const ActualizarProducto = ({ productoActual, onActualizarProducto, onCancelar }) => {
  const [producto, setProducto] = useState({
    nombre: '',
    precio: '',
    descripcion: '',
    caracteristicas: '',
    material: '',
  });

  useEffect(() => {
    if (productoActual) {
      setProducto({
        nombre: productoActual.nombre,
        precio: productoActual.precio,
        descripcion: productoActual.descripcion,
        caracteristicas: productoActual.caracteristicas.join(", "), 
        material: productoActual.material,
      });
    }
  }, [productoActual]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProducto(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onActualizarProducto(productoActual._id, {
      ...producto,
      caracteristicas: producto.caracteristicas.split(", ").map(caracteristica => caracteristica.trim())
    });
  };

  return (
    <div className="container">
      <h2>Actualizar Producto</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Nombre:</label>
          <input name="nombre" type="text" value={producto.nombre} onChange={handleChange} required />
        </div>
        <div>
          <label>Precio:</label>
          <input name="precio" type="number" value={producto.precio} onChange={handleChange} required />
        </div>
        <div>
          <label>Descripción:</label>
          <textarea name="descripcion" value={producto.descripcion} onChange={handleChange} required />
        </div>
        <div>
          <label>Características:</label>
          <input name="caracteristicas" type="text" value={producto.caracteristicas} onChange={handleChange} />
        </div>
        <div>
          <label>Material:</label>
          <input name="material" type="text" value={producto.material} onChange={handleChange} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
          <div>
            <button type="submit">Actualizar</button>
          </div>
          <div>
            <button type="button" onClick={onCancelar}>Cancelar</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ActualizarProducto;
