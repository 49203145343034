import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

export default function DetalleProducto() {
  const { id } = useParams();
  const [producto, setProducto] = useState(null);

  useEffect(() => {
    const fetchProducto = async () => {
      try {
        const response = await axios.get(`https://ironsafe-beta.vercel.app/cajas-fuertes/${id}`);
        setProducto(response.data);
      } catch (error) {
        console.error('Error al obtener el producto:', error);
      }
    };
    fetchProducto();
  }, [id]);

  if (!producto) {
    return <div>Cargando...</div>;
  }

  // Función para dividir las características en dos listas
  const dividirCaracteristicas = (caracteristicas) => {
    const mitad = Math.ceil(caracteristicas.length / 2);
    const primeraMitad = caracteristicas.slice(0, mitad);
    const segundaMitad = caracteristicas.slice(mitad);

    return (
      <div className="caracteristicas-container">
        <ul className="caracteristicas-lista">
          {primeraMitad.map((caracteristica, index) => (
            <li key={index}>{caracteristica}</li>
          ))}
        </ul>
        <ul className="caracteristicas-lista">
          {segundaMitad.map((caracteristica, index) => (
            <li key={index}>{caracteristica}</li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="detalle-producto">
      <h1>{producto.nombre}</h1>
      <img src={producto.imagen} alt={producto.nombre} />
      <h2>${producto.precio}</h2>
      <h2>{producto.material}</h2>
      <p>{producto.descripcion}</p>
      <h2>Caracteristicas</h2>
      {producto.caracteristicas && dividirCaracteristicas(producto.caracteristicas)}
      <button className="boton-comprar">Comprar</button>
    </div>
  );
}
