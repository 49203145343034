import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { Link} from 'react-router-dom';
function Footer() {
    return (
        <div className="footer">
            <div className="footer-content">
                <div className="footer-left">
                    <div className="social-contact">
                        <div className="social-links">
                            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebookF} /> Facebook</a>
                            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} /> Twitter</a>
                            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /> Instagram</a>
                        </div>
                        <div className="contact-links">
                            <a href="mailto:ironsafe3@gmail.com"><FontAwesomeIcon icon={faEnvelope} /> ironsafe3@gmail.com</a>
                            <a href="tel:+7891256905"><FontAwesomeIcon icon={faPhone} /> +52 7891256905</a>
                        </div>
                    </div>
                </div>
                <div className="footer-right">
                    <h2>Acerca de nosotros</h2>
                    <p>Texto con información sobre la empresa.</p>
                    <p>&copy; 2024 Caja Fuerte. Todos los derechos reservados.</p>
                </div>
                <div className="footer-right">
                    <Link to="/FAQ">Preguntas Frecuentes</Link>
                </div>
            </div>
        </div>
    );
}

export default Footer;
