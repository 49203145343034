// En ActualizarPreguntaFrecuente.jsx
import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';


const ActualizarPreguntaFrecuente = ({ pregunta, onPreguntaActualizada, onCancelar }) => {
  const [preguntaFrecuente, setPreguntaFrecuente] = useState(pregunta);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPreguntaFrecuente(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://ironsafe-beta.vercel.app/preguntas-frecuentes/${pregunta._id}`, preguntaFrecuente);
      Swal.fire({
        title: '¡Actualizado!',
        text: 'La pregunta frecuente ha sido actualizada exitosamente.',
        icon: 'success',
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.isConfirmed) {
          onPreguntaActualizada(); // Notificar al componente padre para que refresque la lista de preguntas frecuentes
        }
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'No se pudo actualizar la pregunta frecuente.',
        icon: 'error',
        confirmButtonText: 'Cerrar'
      });
      console.error('Error al actualizar la pregunta frecuente:', error);
    }
  };

  return (
    <div className='container'>
      <h2>Actualizar Pregunta Frecuente</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Pregunta:</label>
          <input name="pregunta" type="text" value={preguntaFrecuente.pregunta} onChange={handleChange} required />
        </div>
        <div>
          <label>Respuesta:</label>
          <textarea name="respuesta" value={preguntaFrecuente.respuesta} onChange={handleChange} required />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
          <button type="submit">Actualizar</button>
          <button type="button" onClick={onCancelar}>Cancelar</button>
        </div>
      </form>
    </div>
  );
};

export default ActualizarPreguntaFrecuente;
