import React, { createContext, useState, useContext } from 'react';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userMac, setUserMac] = useState(null); // Estado para almacenar la MAC del usuario

  // Función para actualizar la información del usuario actual, incluyendo la MAC
  const login = (userData, mac) => {
    setCurrentUser({
      ...userData,
      correo: userData.correo // Asegúrate de que userData incluya el id del usuario
    });
    console.log(userData.correo)
    setUserMac(mac);
    console.log(mac);
  };
  
  const logout = () => {
    setCurrentUser(null);
    setUserMac(null); // Limpia la MAC del usuario al hacer logout
  };

  // Proporcionar userMac y updateUserMac a través del contexto
  const value = {
    currentUser,
    userMac,
    updateUserMac: setUserMac, // Función para actualizar directamente la MAC del usuario
    login,
    logout
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
