// DatosEmpresa.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate en lugar de useHistory

const DatosEmpresa = () => {
  const [empresas, setEmpresa] = useState([]);
  const navigate = useNavigate(); // Usa useNavigate aquí

  useEffect(() => {
    const fetchUsuarios = async () => {
      try {
        const response = await axios.get('https://ironsafe-beta.vercel.app/datos-empresa');
        setEmpresa(response.data);
      } catch (error) {
        console.error('Error al obtener los datos de la empresa:', error);
      }
    };

    fetchUsuarios();
  }, []);

  const handleEdit = (empresa) => {
    navigate('/actualizar-empresa', { state: { empresa } }); 
  };

  return (
    <div>
      <table className="table table-dark">
        <thead>
        <h2>Misión y Visión</h2>
          <tr>
            <th>Título</th>
            <th>Contenido</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {empresas.map((empresa) => (
            <tr key={empresa._id}>
              <td>{empresa.titulo}</td>
              <td>{empresa.contenido}</td>
              <td>
                <button onClick={() => handleEdit(empresa)}>Actualizar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DatosEmpresa;
