import React, { useState, useEffect } from 'react';
import Producto from '../Producto';
import axios from 'axios';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const Catalogo = () => {
  const [productos, setProductos] = useState([]);
  const [filtroMaterial, setFiltroMaterial] = useState('');
  const [paginaActual, setPaginaActual] = useState(1);
  const productosPorPagina = 6;

  useEffect(() => {
    axios.get('https://ironsafe-beta.vercel.app/cajas-fuertes')
      .then(response => {
        setProductos(response.data.map(producto => ({ ...producto, material: producto.material || 'Acero' }))); // Asegura que todos los productos tengan un material
      })
      .catch(error => {
        console.error("Hubo un error al cargar los productos: ", error);
      });
  }, []);

  const productoPrincipal = productos[0] ? productos[0] : null;

  const productosFiltrados = productos.filter((producto, index) =>
    (filtroMaterial === '' || producto.material === filtroMaterial) && index !== 0
  );

  const indiceDelUltimoProducto = paginaActual * productosPorPagina;
  const indiceDelPrimerProducto = indiceDelUltimoProducto - productosPorPagina;
  const productosActuales = productosFiltrados.slice(indiceDelPrimerProducto, indiceDelUltimoProducto);

  const paginate = (numeroDePagina) => setPaginaActual(numeroDePagina);

  return (
    <>
      {productoPrincipal && (
        <div className="producto-principal-container" style={estilosProductoPrincipal}>
          <div style={estilosEncabezados}>
            <h1>Producto más reciente</h1>
            <h2>Consigue ahora tu caja fuerte IRON SAFE</h2>
          </div>
          <Producto producto={productoPrincipal} />
        </div>
      )}
      <div className="filtro-contenedor">
        <h2>Tipo de material:</h2>
        <select value={filtroMaterial} onChange={(e) => setFiltroMaterial(e.target.value)}>
          <option value="">Todos los materiales</option>
          <option value="Acero">Acero</option>
          <option value="Madera reforzada">Madera Reforzada</option>
        </select>
      </div>
      <div className="catalogo-container" style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '20px' }}>
        <TransitionGroup component={null}>
          {productosActuales.map((producto, index) => (
            producto._id !== productoPrincipal._id && (
              <CSSTransition
                key={producto._id}
                timeout={500}
                classNames="item"
              >
                <div>
                  <Producto producto={producto} />
                </div>
              </CSSTransition>
            )
          ))}
        </TransitionGroup>
      </div>
      <div className="botonera">
        {[...Array(Math.ceil(productosFiltrados.length / productosPorPagina)).keys()].map(numero => (
          <button key={numero} onClick={() => paginate(numero + 1)}>
            {numero + 1}
          </button>
        ))}
      </div>
    </>
  );
};
// Estilos para el producto principal
const estilosProductoPrincipal = {
  margin: "20px auto",
  display: "flex",
  flexDirection: "column", // Asegúrate de que los elementos se apilen verticalmente
  justifyContent: "center",
  alignItems: "center", // Centra horizontalmente los elementos hijos
  padding: "20px",
  border: "3px solid #007bff",
  borderRadius: "10px",
  backgroundColor: "#f8f9fa17",
  boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
  maxWidth: "80%",
};
const estilosEncabezados = {
  textAlign: "center", // Centra el texto de los encabezados
  marginBottom: "20px", // Agrega espacio entre los encabezados y el producto
};

export default Catalogo;
