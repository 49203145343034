import React from 'react';



const BienvenidaCliente = () => {
  return (
    <div className="container">
      <h1>¡Bienvenido a SafeMonitor!</h1>
      <p>La herramienta definitiva para el monitoreo y gestión de tu caja fuerte.</p>
      <img 
        src={require("../../assets/cliente.jpg")} 
        alt="Caja Fuerte" 
        style={{ 
          marginTop: '20px', 
          maxWidth: '100%', // Ajusta esto para controlar el tamaño máximo de la imagen
          width: '30%', // Ajusta el ancho de la imagen aquí
          height: 'auto',
          display: 'block', // Usa display block para permitir margen automático
          marginLeft: 'auto', // Margen automático a la izquierda
          marginRight: 'auto', // Margen automático a la derecha
          borderRadius: 10
        }} 
      />
      
      <div style={{ marginTop: '30px', textAlign: 'left' }}>
        <h2>Características Principales:</h2>
        <ul>
          <li><strong>Monitoreo en Tiempo Real:</strong> Mantén un seguimiento constante del estado de tu caja fuerte, incluyendo alertas de seguridad y acceso.</li>
          <li><strong>Gestión de Perfil:</strong> Edita tu perfil para mantener tus datos siempre actualizados y asegurar una experiencia personalizada.</li>
        </ul>

        <p>En IRON SAFE, te ofrecemos la tranquilidad de saber que tus valores están protegidos con la más alta tecnología, permitiéndote acceder y gestionar tu caja fuerte desde cualquier lugar y momento.</p>
      </div>
    </div>
  );
};

export default BienvenidaCliente;
