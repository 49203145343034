import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';

export default function VerificarCodigoPregunta() {
  const [codigo, setCodigo] = useState('');
  const location = useLocation(); 
  const navigate = useNavigate();
  const email = location.state?.email; 

  useEffect(() => {
    if (!email) {
      navigate('/recuperar-contrasena'); 
    }
  }, [email, navigate]);

  const verificarCodigo = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://ironsafe-beta.vercel.app/verificar-codigo', {
        correo: email,
        codigo,
      });
      if (response.data) {
        Swal.fire({
          icon: 'success',
          title: '¡Código verificado correctamente!',
          confirmButtonText: 'Continuar'
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/Pregunta', { state: { email } });
          }
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Error: ${error.response ? error.response.data : 'No se pudo conectar al servidor'}`,
        confirmButtonText: 'Intentar de nuevo'
      });
    }
  };

  return (
    <div className="container">
      <h2>Verificar Código</h2>
      <form onSubmit={verificarCodigo}>
        <label>Código de Verificación:</label>
        <input
          type="text"
          value={codigo}
          onChange={(e) => setCodigo(e.target.value)}
          required
        />
        <button type="submit">Verificar Código</button>
      </form>
    </div>
  );
}
