import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; 

function Header() {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  return (
    <>
      <div className="nav">
        <div className="nav-brand">
          <img src={require("../assets/logo.png")} alt="Logo" style={{ width: '90px' }} />
          <h3>IRON SAFE</h3>
        </div>
        <div className="nav-links">
          <Link to="/Inicio">Inicio</Link>
          <Link to="/Catalogo">Catalogo</Link>
          <Link to="/Politicas">Nosotros</Link>
          <Link to="/Contacto">Contacto</Link>
          {currentUser ? (
            <button onClick={handleLogout}>Cerrar Sesión</button>
          ) : (
            <Link to="/Login">Login</Link>
          )}
        </div>
      </div>

      {currentUser && currentUser.tipo === 'administrador' && (
        <div className="sidebar admin-sidebar">
          <Link to="/bienvenidaAdmin">Inicio Admin</Link>
          <Link to="/Usuarios">Usuarios</Link>
          <Link to="/Productos">Productos</Link>
          <Link to="/PreguntasAdmin">FAQ</Link>
          <Link to="/empresa">Mision-Vision</Link>
          
        </div>
      )}

      {currentUser && currentUser.tipo === 'cliente' && (
        <div className="sidebar cliente-sidebar">
          <Link to="/BienvenidaCliente">Inicio Cliente</Link>
          <Link to="/estadistica">Estadísticas</Link>
          <Link to="/Perfil">Perfil</Link>
        </div>
      )}
    </>
  );
}

export default Header;
