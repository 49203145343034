import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';


const ResponderPregunta = () => {
  const [pregunta, setPregunta] = useState('');
  const [respuesta, setRespuesta] = useState('');
  const [cargando, setCargando] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email;

  useEffect(() => {
    const obtenerPreguntaSeguridad = async () => {
      try {
        const response = await axios.get(`https://ironsafe-beta.vercel.app/obtener-pregunta-seguridad/${email}`);
        setPregunta(response.data.pregunta);
        setCargando(false);
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error al obtener la pregunta de seguridad. Por favor, inténtalo de nuevo más tarde.',
          confirmButtonText: 'Entendido'
        }).then(() => {
          navigate('/recuperar-contrasena');
        });
      }
    };

    if (email) {
      obtenerPreguntaSeguridad();
    } else {
      navigate('/recuperar-contrasena');
    }
  }, [email, navigate]);

  const verificarRespuesta = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`https://ironsafe-beta.vercel.app/verificar-respuesta-seguridad`, {
        correo: email,
        respuesta,
      });
      if (response.data.esCorrecta) {
        Swal.fire({
          icon: 'success',
          title: 'Respuesta correcta',
          text: 'Ahora puedes restablecer tu contraseña.',
          confirmButtonText: 'Continuar'
        }).then(() => {
          navigate('/nueva-contrasena', { state: { email } });
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Respuesta incorrecta',
          text: 'Inténtalo de nuevo.',
          confirmButtonText: 'Intentar de nuevo'
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error al verificar la respuesta. Por favor, inténtalo de nuevo más tarde.',
        confirmButtonText: 'Intentar de nuevo'
      });
    }
  };

  if (cargando) return <p>Cargando pregunta de seguridad...</p>;

  return (
    <div className="container">
      <h2>Responder Pregunta de Seguridad</h2>
      <form onSubmit={verificarRespuesta}>
        <p>{pregunta}</p>
        <input
          type="text"
          value={respuesta}
          onChange={(e) => setRespuesta(e.target.value)}
          required
        />
        <button type="submit">Verificar Respuesta</button>
      </form>
    </div>
  );
};

export default ResponderPregunta;
