import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './componentes/AuthContext';
import './App.css';

// Importa tus componentes
import Login from './componentes/Login';
import Registro from './componentes/Registro';
import Estadistica from './componentes/Cliente/Estadisticas';
import Header from './componentes/Header';
import Footer from './componentes/Footer';
import Politicas from './componentes/Politicas';
import Contacto from './componentes/Public/Contacto';
import Usuarios from './componentes/Administrador/Usuarios';
import Inicio from './componentes/Public/Inicio';
import RecuperarContrasena from './componentes/RecuperarContrasena';
import VerificarCodigo from './componentes/VerificarCodigo';
import NuevaContrasena from './componentes/NuevaContrasena';
import Productos from './componentes/Administrador/Productos';
import DetalleProducto from './componentes/Public/DetalleProducto';
import DatosEmpresa from './componentes/Administrador/DatosEmpresa';
import ActualizarEmpresa from './componentes/Administrador/ActualizarEmpresa';
import BienvenidaAdmin from './componentes/Administrador/Bienvenida';
import BienvenidaCliente from './componentes/Cliente/Bienvenida2';
import RecuperarContrasenaOpciones from './componentes/RecuperarContrasenaOpciones';
import MandarCorreo from './componentes/MandarCorreo';
import VerificarCodigoPregunta from './componentes/VerificarCodigoPregunta';
import ResponderPregunta from './componentes/Pregunta';
import PreguntasFrecuentes from './componentes/PreguntasFrecuentes';
import PreguntasFrecuentesAdmin from './componentes/Administrador/PreguntasFrecuentesAdmin';
import PerfilUsuario from './componentes/Cliente/PerfilUsuario';
import Catalogo from './componentes/Public/Catalogo';

const ProtectedRoute = ({ children }) => {
  const { currentUser } = useAuth();
  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <Header />
        <div className="App">
          <Routes>
            <Route path="/" element={<Inicio />} />
            <Route path="/Inicio" element={<Inicio />} />
            <Route path="/login" element={<Login />} />
            <Route path="/registro" element={<Registro />} />
            <Route path="/estadistica" element={<ProtectedRoute><Estadistica /></ProtectedRoute>} />
            <Route path="/Contacto" element={<Contacto />} />
            <Route path="/Politicas" element={<Politicas />} />
            <Route path="/Usuarios" element={<ProtectedRoute><Usuarios /></ProtectedRoute>} />
            <Route path="/recuperar-contrasena" element={<RecuperarContrasena />} />
            <Route path="/verificar-codigo" element={<VerificarCodigo />} />
            <Route path="/nueva-contrasena" element={<NuevaContrasena />} />
            <Route path="/Productos" element={<ProtectedRoute><Productos /></ProtectedRoute>} />
            <Route path="/producto/:id" element={<DetalleProducto/>} />
            <Route path="/opciones" element={<RecuperarContrasenaOpciones/>} />
            <Route path="/EnviarCorreo" element={<MandarCorreo/>} />
            <Route path="/CodigoPregunta" element={<VerificarCodigoPregunta/>} />
            <Route path="/Pregunta" element={<ResponderPregunta/>} />
            <Route path="/FAQ" element={<PreguntasFrecuentes/>} />
            <Route path="/Catalogo" element={<Catalogo/>} />
            <Route path="/empresa" element={<ProtectedRoute><DatosEmpresa/></ProtectedRoute>} />
            <Route path="/actualizar-empresa" element={<ProtectedRoute><ActualizarEmpresa/></ProtectedRoute>} />
            <Route path="/bienvenidaAdmin" element={<ProtectedRoute><BienvenidaAdmin/></ProtectedRoute>} />
            <Route path="/bienvenidaCliente" element={<ProtectedRoute><BienvenidaCliente/></ProtectedRoute>} />
            <Route path="/PreguntasAdmin" element={<ProtectedRoute><PreguntasFrecuentesAdmin/></ProtectedRoute>} />
            <Route path="/Perfil" element={<ProtectedRoute><PerfilUsuario/></ProtectedRoute>} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </AuthProvider>
  );
}

export default App;
