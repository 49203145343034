import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const Registro = () => {
  const navigate = useNavigate();
  const [nombre, setNombre] = useState('');
  const [usuario, setUsuario] = useState('');
  const [email, setEmail] = useState('');
  const [contrasena, setContrasena] = useState('');
  const [confirmarContrasena, setConfirmarContrasena] = useState('');
  const [mostrarContrasena, setMostrarContrasena] = useState(false);
  const [mostrarConfirmarContrasena, setMostrarConfirmarContrasena] = useState(false);
  const [preguntaSeguridad, setPreguntaSeguridad] = useState('');
  const [respuestaSeguridad, setRespuestaSeguridad] = useState('');
  const [errores, setErrores] = useState({});

  const validarNombre = (nombre) => nombre.length >= 2;
  const validarUsuario = (usuario) => usuario.length >= 2;
  const validarEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const validarContrasena = (password) => {
    let errores = [];
    if (password.length < 8) errores.push("al menos 8 caracteres");
    if (!/[A-Z]/.test(password)) errores.push("una mayúscula");
    if (!/\d/.test(password)) errores.push("un número");
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) errores.push("un símbolo especial (@, #, $, etc.)");
    return errores;
  };

  useEffect(() => {
    validarCampos();
  }, [nombre, usuario, email, contrasena, confirmarContrasena]);

  const validarCampos = () => {
    let erroresActuales = {};
    if (!validarNombre(nombre)) erroresActuales.nombre = 'El nombre debe tener al menos 2 caracteres.';
    if (!validarUsuario(usuario)) erroresActuales.usuario = 'El usuario debe tener al menos 2 caracteres.';
    if (!validarEmail(email)) erroresActuales.email = 'Ingrese un correo electrónico válido.';
    const erroresContrasenaList = validarContrasena(contrasena);
    if (erroresContrasenaList.length > 0) erroresActuales.contrasena = `La contraseña debe tener ${erroresContrasenaList.join(", ")}.`;
    if (contrasena !== confirmarContrasena) erroresActuales.confirmarContrasena = 'Las contraseñas no coinciden.';
    
    setErrores(erroresActuales);
  
    return Object.keys(erroresActuales).length === 0;
  };
  


  const registrarse = async () => {
    if (!validarNombre(nombre)) {
      Swal.fire({
        title: 'Error!',
        text: 'El nombre debe tener al menos 2 caracteres.',
        icon: 'error',
        confirmButtonText: 'Cerrar'
      });
      return;
    }
    if (!validarUsuario(usuario)) {
      Swal.fire({
        title: 'Error!',
        text: 'El usuario debe tener al menos 2 caracteres.',
        icon: 'error',
        confirmButtonText: 'Cerrar'
      });
      return;
    }
    if (!validarEmail(email)) {
      Swal.fire({
        title: 'Error!',
        text: 'Ingrese un correo electrónico válido.',
        icon: 'warning',
        confirmButtonText: 'Cerrar'
      });
      return;
    }

    const erroresContrasena = validarContrasena(contrasena);
    if (erroresContrasena.length > 0) {
      Swal.fire({
        title: 'Error!',
        text: `La contraseña debe tener ${erroresContrasena.join(", ")}.`,
        icon: 'error',
        confirmButtonText: 'Cerrar'
      });
      return;
    }

    if (contrasena !== confirmarContrasena) {
      Swal.fire({
        title: 'Error!',
        text: 'Las contraseñas no coinciden.',
        icon: 'error',
        confirmButtonText: 'Cerrar'
      });
      return;
    }

    try {
      const response = await fetch('https://ironsafe-beta.vercel.app/registro', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nombre: nombre,
          nombre_usuario: usuario,
          correo: email,
          contraseña: contrasena,
          pregunta: preguntaSeguridad,
          respuesta: respuestaSeguridad,
          tipo: 'cliente'
        }),
      });

      if (!response.ok) {
        throw new Error('Respuesta del servidor no fue OK');
      }
      await response.json();
      Swal.fire({
        title: '¡Éxito!',
        text: 'Registro exitoso.',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/login');
        }
      });
    } catch (error) {
      console.error('Error en el registro:', error);
      Swal.fire({
        title: 'Error!',
        text: 'Error al registrar. Por favor, intente de nuevo más tarde.',
        icon: 'error',
        confirmButtonText: 'Cerrar'
      });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if(validarCampos()) {
      registrarse();
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Por favor, corrige los errores antes de registrarte.',
        icon: 'error',
        confirmButtonText: 'Cerrar'
      });
    }
  };
  

  return (
    <div className="container" style={{ maxWidth: '600px', margin: '0 auto', textAlign: 'center' }}>
      <img src={require("../assets/icon-login.png")} alt="logo" style={{ width: '90px', marginBottom: '20px' }} />
      <h1 style={{ marginBottom: '20px' }}>Registrarse</h1>
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: '20px' }}>
        {errores.nombre && <div style={{ color: 'red' }}>{errores.nombre}</div>}
        <label htmlFor="usuario" style={{ width: '100%', textAlign: 'left' }}>Nombre</label>
          <input
            type="text"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            required
            style={{ width: '100%', padding: '10px' }}
          />
        </div>
        {errores.usuario && <div style={{ color: 'red' }}>{errores.usuario}</div>}
        <label htmlFor="usuario" style={{ width: '100%', textAlign: 'left' }}>Usuario</label>
        <div style={{ marginBottom: '20px' }}>
          <input
            type="text"
            value={usuario}
            onChange={(e) => setUsuario(e.target.value)}
            required
            style={{ width: '100%', padding: '10px' }}
          />
        </div>
        {errores.email && <div style={{ color: 'red' }}>{errores.email}</div>}
        <label htmlFor="usuario" style={{ width: '100%', textAlign: 'left' }}>Email</label>
        <div style={{ marginBottom: '20px' }}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={{ width: '100%', padding: '10px' }}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <label>Pregunta de Seguridad:</label>
          <select
            value={preguntaSeguridad}
            onChange={(e) => setPreguntaSeguridad(e.target.value)}
            required
          >
            <option value="">--Seleccione una pregunta--</option>
            <option value="¿Nombre de tu mascota?">¿Nombre de tu mascota?</option>
            <option value="¿Quién es tu superhéroe favorito?">¿Quién es tu superhéroe favorito?</option>
            <option value="¿Cuál es tu comida favorita?">¿Cuál es tu comida favorita?</option>
          </select>
        </div>
        <div style={{ marginBottom: '20px' }}>
          <label>Respuesta de Seguridad:</label>
          <input
            type="text"
            value={respuestaSeguridad}
            onChange={(e) => setRespuestaSeguridad(e.target.value)}
            required
          />
        </div>
        {errores.contrasena && <div style={{ color: 'red' }}>{errores.contrasena}</div>}
        <label htmlFor="usuario" style={{ width: '100%', textAlign: 'left' }}>Password</label>
        <div style={{ marginBottom: '20px', position: 'relative' }}>
          <input
            type={mostrarContrasena ? "text" : "password"}
            value={contrasena}
            onChange={(e) => setContrasena(e.target.value)}
            required
            style={{ width: '100%', padding: '10px' }}
          />
          <button type="button" onClick={() => setMostrarContrasena(!mostrarContrasena)} style={{ position: 'absolute', right: '10px', top: '10px', cursor: 'pointer', background: 'none', border: 'none' }}>
            {mostrarContrasena ? "Ocultar" : "Mostrar"}
          </button>
        </div>
        <label htmlFor="usuario" style={{ width: '100%', textAlign: 'left' }}>Confirmar password</label>
        <div style={{ marginBottom: '20px', position: 'relative' }}>
          <input
            type={mostrarConfirmarContrasena ? "text" : "password"}
            value={confirmarContrasena}
            onChange={(e) => setConfirmarContrasena(e.target.value)}
            required
            style={{ width: '100%', padding: '10px' }}
          />
          <button type="button" onClick={() => setMostrarConfirmarContrasena(!mostrarConfirmarContrasena)} style={{ position: 'absolute', right: '10px', top: '10px', cursor: 'pointer', background: 'none', border: 'none' }}>
            {mostrarConfirmarContrasena ? "Ocultar" : "Mostrar"}
          </button>
        </div>
        <button type="submit" style={{ width: '100%', padding: '10px', backgroundColor: 'green', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
          Registrarse
        </button>
      </form>
    </div>
  );
};

export default Registro;
