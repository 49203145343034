import { Link } from 'react-router-dom';

const Producto = ({ producto }) => {
  if (!producto) {
    return <div>Cargando producto...</div>;
  }

  return (
    <div className="producto-container">
      <h1>{producto.nombre}</h1>
      <img src={producto.imagen} alt={producto.nombre} />
      <h2>${producto.precio}</h2>
      <h2>{producto.material}</h2>
      <p>{producto.descripcion}</p>
      <Link to={`/producto/${producto._id}`}>
        <button className="boton-comprar">Detalle</button>
      </Link>
    </div>
  );
};

export default Producto;
