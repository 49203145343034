import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
export default function RecuperarContrasena() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate(); 

  const solicitarRestablecimiento = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://ironsafe-beta.vercel.app/solicitar-recuperacion', { correo: email });
      console.log(response.data);
      
      Swal.fire({
        icon: 'success',
        title: '¡Correo enviado!',
        text: 'Si tu correo está registrado, recibirás un código para verificar tu cuenta.',
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/verificar-codigo', { state: { email } });
        }
      });

    } catch (error) {
      console.error('Error al solicitar la recuperación de contraseña', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Error al solicitar la recuperación de contraseña, verifica el correo.',
        confirmButtonText: 'Intentar de nuevo'
      });
    }
  };

  return (
    <div className="container">
      <h1>Recuperar Contraseña</h1>
      <form onSubmit={solicitarRestablecimiento}>
        <label>Email:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit">Solicitar Restablecimiento</button>
      </form>
    </div>
  );
}
