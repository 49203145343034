// ActualizarUsuario.js

import React, { useState, useEffect } from 'react';

const ActualizarUsuario = ({ usuario, onActualizarUsuario, onCancelar }) => {
  const [datosUsuario, setDatosUsuario] = useState({
    nombre: '',
    correo: '',
    nombre_usuario: '',
  });

  useEffect(() => {
    if (usuario) {
      setDatosUsuario(usuario);
    }
  }, [usuario]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDatosUsuario(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onActualizarUsuario(datosUsuario);
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <label>
          Nombre:
          <input type="text" name="nombre" value={datosUsuario.nombre} onChange={handleChange} />
        </label>
        <label>
          Correo:
          <input type="email" name="correo" value={datosUsuario.correo} onChange={handleChange} />
        </label>
        <label>
          Nombre de Usuario:
          <input type="text" name="nombre_usuario" value={datosUsuario.nombre_usuario} onChange={handleChange} />
        </label>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
          <div>
            <button type="submit">Actualizar</button>
          </div>
          <div>
            <button type="button" onClick={onCancelar}>Cancelar</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ActualizarUsuario;
