import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AgregarProducto from './AgregarProducto'; // Asegura la correcta importación
import Modal from './Modal'; // Verifica la ruta
import Swal from 'sweetalert2';
import ActualizarProducto from './ActualizarProducto'; // Verifica la importación

const Productos = () => {
  const [productos, setProductos] = useState([]);
  const [mostrarAgregar, setMostrarAgregar] = useState(false);
  const [productoDetalle, setProductoDetalle] = useState(null);
  const [productoEditar, setProductoEditar] = useState(null);

  useEffect(() => {
    fetchProductos();
  }, []);

  const fetchProductos = async () => {
    try {
      const response = await axios.get('https://ironsafe-beta.vercel.app/cajas-fuertes');
      setProductos(response.data);
    } catch (error) {
      console.error('Error al obtener los productos:', error);
    }
  };

  const eliminarProducto = async (id) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`https://ironsafe-beta.vercel.app/cajas-fuertes/${id}`);
          fetchProductos();
          Swal.fire('Eliminado!', 'El producto ha sido eliminado.', 'success');
        } catch (error) {
          Swal.fire('Error', 'Error al eliminar el producto', 'error');
        }
      }
    });
  }
  
  const actualizarProducto = async (id, datosProducto) => {
    try {
      await axios.put(`https://ironsafe-beta.vercel.app/cajas-fuertes/${id}`, datosProducto);
      fetchProductos();
      setProductoEditar(null);
      Swal.fire('¡Actualizado!', 'El producto ha sido actualizado exitosamente.', 'success');
    } catch (error) {
      Swal.fire('Error', 'Error al actualizar el producto', 'error');
    }
  };

  const agregarProducto = async (producto) => {
    try {
      await axios.post('https://ironsafe-beta.vercel.app/cajas-fuertes', producto);
      fetchProductos();
      setMostrarAgregar(false);
      Swal.fire('¡Agregado!', 'El producto ha sido agregado exitosamente.', 'success');
    } catch (error) {
      Swal.fire('Error', 'Error al agregar el producto', 'error');
    }
  };

  const mostrarDetalle = (producto) => {
    setProductoDetalle(producto);
  };

  const mostrarActualizar = (producto) => {
    setProductoEditar(producto);
  };

  if (mostrarAgregar) {
    return (
      <AgregarProducto
        onAgregarProducto={agregarProducto} 
        onCancelar={() => setMostrarAgregar(false)}
      />
    );
  }

  if (productoEditar) {
    return (
      <ActualizarProducto
        productoActual={productoEditar} 
        onActualizarProducto={actualizarProducto}
        onCancelar={() => setProductoEditar(null)}
      />
    );
  }
  const renderCaracteristicas = (caracteristicas) => {
    if (Array.isArray(caracteristicas)) {
      return (
        <ul>
          {caracteristicas.map((caracteristica, index) => (
            <li key={index}>{caracteristica}</li>
          ))}
        </ul>
      );
    }
    return caracteristicas;
  };
  return (
    <div>
      <table className="table table-dark">
        <thead>
          <h2>Productos</h2>
          <button onClick={() => setMostrarAgregar(true)}>Agregar Producto</button>
          <tr>
            <th>Nombre</th>
            <th>Precio</th>
            <th>Tipo Material</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {productos.map(producto => (
            <tr key={producto._id}>
              <td>{producto.nombre}</td>
              <td>${producto.precio}</td>
              <td>{producto.material}</td>
              <td>
                <button style={{ marginRight: '10px',marginBottom: '10px' }} onClick={() => mostrarDetalle(producto)}>Ver más</button>
                <button style={{ marginRight: '10px' }} onClick={() => mostrarActualizar(producto)}>Actualizar</button>
                <button onClick={() => eliminarProducto(producto._id)}>Eliminar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {productoDetalle && (
        <Modal onClose={() => setProductoDetalle(null)}>
          <h3>{productoDetalle.nombre}</h3>
          <p>Descripcion: {productoDetalle.descripcion}</p>
          <div>Características: {renderCaracteristicas(productoDetalle.caracteristicas)}</div>
          {productoDetalle.imagen && (
            <img src={productoDetalle.imagen} alt={productoDetalle.nombre} style={{ maxWidth: "100%", maxHeight: "400px" }} />
          )}
        </Modal>
      )}
    </div>
  );
};

export default Productos;
