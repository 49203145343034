import React from 'react';
import { useNavigate } from 'react-router-dom';

const RecuperarContrasenaOpciones = () => {
  const navigate = useNavigate();

  const handleRecuperarCodigo = () => {
    navigate('/recuperar-contrasena');
  };

  const handleRecuperarPregunta = () => {
    navigate('/EnviarCorreo');
  };

  return (
    <div className="container" style={{ maxWidth: '400px', margin: '0 auto', textAlign: 'center' }}>
      <h2>Recuperar Contraseña</h2>
      <p>Elige el método de recuperación de contraseña:</p>
      <button onClick={handleRecuperarCodigo} style={{ marginRight: '10px' }}>Con Correo</button>
      <button onClick={handleRecuperarPregunta}>Con Pregunta Secreta</button>
    </div>
  );
};

export default RecuperarContrasenaOpciones;
