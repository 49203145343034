import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Accordion from 'react-bootstrap/Accordion';

function PreguntasFrecuentes() {
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    axios.get('https://ironsafe-beta.vercel.app/preguntas-frecuentes')
      .then(response => {
        setFaqs(response.data);
      })
      .catch(error => console.error('Hubo un error al obtener las preguntas frecuentes', error));
  }, []);

  return (
    <div className="container">
      <h2>Preguntas Frecuentes</h2>
      <Accordion defaultActiveKey="0">
        {faqs.map((faq, index) => (
          <Accordion.Item eventKey={String(index)} key={faq._id}>
            <Accordion.Header>{faq.pregunta}</Accordion.Header>
            <Accordion.Body>
              {faq.respuesta}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
}

export default PreguntasFrecuentes;
