import React from 'react';
import Galeria from './Galeria'; 
import Destacados from './Destacados';

const Inicio = () => {
  return (
    <div>
      <Galeria />
      <Destacados/>
    </div>
  );
};

export default Inicio;
