import React, { useState, useEffect } from 'react';
import Producto from '../Producto';
import axios from 'axios';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const Destacados = () => {
    const [productos, setProductos] = useState([]);

    useEffect(() => {
        axios.get('https://ironsafe-beta.vercel.app/cajas-fuertes')
            .then(response => {
                setProductos(response.data.map(producto => ({ ...producto, material: producto.material || 'Acero' })));
            })
            .catch(error => {
                console.error("Hubo un error al cargar los productos: ", error);
            });
    }, []);
    const productoPrincipal = productos[0] ? productos[0] : null;
    const productosMasVendidos = productos.slice(1, 7);

    return (
        <>
            {productoPrincipal && (
                <div className="producto-principal-container" style={estilosProductoPrincipal}>
                    <div style={estilosEncabezados}>
                        <h1>Producto más reciente</h1>
                        <h2>Consigue ahora tu caja fuerte IRON SAFE</h2>
                    </div>
                    <Producto producto={productoPrincipal} />
                </div>
            )}
            <div style={estilosEncabezados}>
                <h1>Productos mas vendidos</h1>
            </div>
            <div className="catalogo-container" style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '20px', marginTop: '20px' }}>
                <TransitionGroup component={null}>
                    {productosMasVendidos.map((producto) =>
                        producto && (
                            <CSSTransition key={producto._id} timeout={500} classNames="item">
                                <div>
                                    <Producto producto={producto} />
                                </div>
                            </CSSTransition>
                        )
                    )}
                </TransitionGroup>
            </div>
        </>
    );
};

const estilosProductoPrincipal = {
    margin: "20px auto",
    display: "flex",
    flexDirection: "column", // Asegúrate de que los elementos se apilen verticalmente
    justifyContent: "center",
    alignItems: "center", // Centra horizontalmente los elementos hijos
    padding: "20px",
    border: "3px solid #007bff",
    borderRadius: "10px",
    backgroundColor: "#f8f9fa17",
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
    maxWidth: "80%",
};

const estilosEncabezados = {
    textAlign: "center", // Centra el texto de los encabezados
    marginBottom: "20px", // Agrega espacio entre los encabezados y el producto
};

export default Destacados;
