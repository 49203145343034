import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AgregarPreguntaFrecuente from './AgregarPreguntaFrecuente'; // Asegura la correcta importación
import ActualizarPreguntaFrecuente from './ActualizarPreguntaFrecuente'; // Verifica la importación

const PreguntasFrecuentesAdmin = () => {
    const [preguntas, setPreguntas] = useState([]);
    const [mostrarAgregar, setMostrarAgregar] = useState(false);
    const [preguntaEditar, setPreguntaEditar] = useState(null);

    useEffect(() => {
        fetchPreguntasFrecuentes();
    }, []);

    const fetchPreguntasFrecuentes = async () => {
        try {
            const response = await axios.get('https://ironsafe-beta.vercel.app/preguntas-frecuentes');
            setPreguntas(response.data);
        } catch (error) {
            console.error('Error al obtener las preguntas frecuentes:', error);
        }
    };

    const eliminarPregunta = async (id) => {
        try {
            await axios.delete(`https://ironsafe-beta.vercel.app/preguntas-frecuentes/${id}`);
            fetchPreguntasFrecuentes();
        } catch (error) {
            console.error('Error al eliminar la pregunta:', error);
        }
    };

    const handlePreguntaAgregada = () => {
        fetchPreguntasFrecuentes();
        setMostrarAgregar(false);
    };

    const handlePreguntaActualizada = () => {
        fetchPreguntasFrecuentes();
        setPreguntaEditar(null);
    };

    // Uso de los componentes AgregarPreguntaFrecuente y ActualizarPreguntaFrecuente
    if (mostrarAgregar) {
        return <AgregarPreguntaFrecuente onPreguntaAgregada={handlePreguntaAgregada} onCancelar={() => setMostrarAgregar(false)} />;
    }

    if (preguntaEditar) {
        return <ActualizarPreguntaFrecuente pregunta={preguntaEditar} onPreguntaActualizada={handlePreguntaActualizada} onCancelar={() => setPreguntaEditar(null)} />;
    }

    

    return (
        <div>
            <table className="table table-dark">
                <thead>
                    <h2>Preguntas Frecuentes</h2>
                    <button onClick={() => setMostrarAgregar(true)} style={{ marginBottom: '20px' }}>Agregar Pregunta</button>
                    <tr>
                        <th>Pregunta</th>
                        <th>Respuesta</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {preguntas.map((pregunta, index) => (
                        <tr key={pregunta._id || index}>
                            <td>{pregunta.pregunta}</td>
                            <td>{pregunta.respuesta}</td>
                            <td>
                                <button onClick={() => setPreguntaEditar(pregunta)} style={{ marginBottom: '10px' }}>Editar</button>
                                <button onClick={() => eliminarPregunta(pregunta._id)}>Eliminar</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

};

export default PreguntasFrecuentesAdmin;
